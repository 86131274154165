import { InMemoryDbService } from 'angular-in-memory-web-api';
import { PlantLogic } from 'app/fake-db/plantlogic';

export class FakeDbService implements InMemoryDbService {
  createDb(): any {
    return {
      // PlantLogic APIs
      'plantlogic-device-uplinks-records': PlantLogic.records
    };
  }
}
