import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AlarmModalData } from '../../manage-nodes/node-list/node-list.component';

const POSSIBLE_ALARM_TYPES = [
  ['low_value', 'Low Value'],
  ['high_value', 'High Value'],
  ['low_value_delay', 'Low Value Delay'],
  ['high_value_delay', 'High Value Delay'],
  ['low_value_repeat', 'Low Value Repeat Delay'],
  ['high_value_repeat', 'High Value Repeat Delay']
];

@Component({
  selector: 'app-alarm-modal',
  templateUrl: './alarm-modal.component.html',
  styleUrls: ['./alarm-modal.component.scss']
})
export class AlarmModalComponent implements OnInit {
  filteredAlarms = [];

  constructor(
    public alarmModalRef: MatDialogRef<AlarmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlarmModalData
  ) { }

  ngOnInit() {
    this.filterAlarms(this.data.alarmConfig.alarm_type);
  }

  filterAlarms(alarmType: string) {
    if (alarmType === 'BatteryVolts') {
      this.filteredAlarms = POSSIBLE_ALARM_TYPES.filter(x => x[0].substring(0, 4) !== 'high');
    } else {
      this.filteredAlarms = POSSIBLE_ALARM_TYPES;
    }
  }

  onNoClick(): void {
    this.alarmModalRef.close(undefined);
  }
}
