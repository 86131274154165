import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalibrateModalComponent } from './calibrate-modal.component';
import { MaterialModule } from '../../shared-module/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FuseSharedModule } from '@fuse/shared.module';
import { SharedService } from '../../../../utils/sharedService';

@NgModule({
  declarations: [
    CalibrateModalComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FuseSharedModule,
    ReactiveFormsModule
  ],
  providers: [
    SharedService
  ]
})
export class CalibrateModalModule { }
