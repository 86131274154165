import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
  public confirmModal: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ConfirmModalComponent>) 
  { 
    this.confirmModal = data;
  }
  
  ngOnInit(): void {}
  
  cancel(): any {
    this.dialogRef.close({ data: false });
  }

  confirm(): any {
    this.dialogRef.close({ data: true });
  }
}
