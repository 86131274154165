import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
  {
    id: 'dashboards',
    title: 'Dashboard',
    translate: 'NAV.DASHBOARD',
    type: 'item',
    icon: 'dashboard',
    classes: 'navBarItems',
    url: '/apps/dashboards/analytics'
  },
  // {
  //   id: 'map',
  //   title: 'Map',
  //   translate: 'NAV.MAP',
  //   type: 'item',
  //   icon: 'map',
  //   classes: 'navBarItems',
  //   url: '/map'
  // },
  {
    id: 'about',
    title: 'About',
    translate: 'NAV.ABOUT',
    type: 'item',
    icon: 'info',
    classes: 'navBarItems',
    url: '/about'
  },
  {
    id: 'support',
    title: 'Support',
    translate: 'NAV.SUPPORT',
    type: 'item',
    icon: 'contact_support',
    classes: 'navBarItems',
    url: '/faq'
  }
];
