import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthService } from '../app/main/pages/authentication/auth.service';
import { SharedService } from '../utils/sharedService';

const route: Routes = [
  {
    path: 'auth/reset-password',
    loadChildren: './main/pages/authentication/reset-password/reset-password.module#ResetPasswordModule'
  },
  {
    path: 'auth/login',
    loadChildren: './main/pages/authentication/login/login.module#LoginModule',
    canActivate: [SharedService]
  },
  {
    path: 'auth/forgot-password',
    loadChildren: './main/pages/authentication/forgot-password/forgot-password.module#ForgotPasswordModule',
    canActivate: [SharedService]
  },
  {
    path: 'apps',
    loadChildren: './main/apps/apps.module#AppsModule',
    canActivate: [AuthService]
  },
  {
    path: 'faq',
    loadChildren: './main/pages/faq/faq.module#FaqModule',
    canActivate: [AuthService]
  },
  {
    path: 'profile',
    loadChildren: './main/pages/profile/profile.module#ProfileModule',
    canActivate: [AuthService]
  },
  {
    path: 'notifications',
    loadChildren: './main/pages/notification/notification.module#NotificationModule',
    canActivate: [AuthService]
  },
  {
    path: 'history',
    loadChildren: './main/pages/history/history.module#HistoryModule',
    canActivate: [AuthService]
  },
  {
    path: 'manage-nodes',
    loadChildren: './main/pages/manage-nodes/manage-nodes.module#ManageNodesModule',
    canActivate: [AuthService]
  },
  {
    path: 'about',
    loadChildren: './main/pages/about/about.module#AboutModule',
    canActivate: [AuthService]
  },
  {
    path: '404',
    loadChildren: './main/pages/404/error-404.module#Error404Module',
    canActivate: [AuthService]
  },
  {
    path: '',
    redirectTo: 'apps/dashboards/analytics',
    canActivate: [AuthService],
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'auth/login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(route)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
