import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
declare const google: any;

@Injectable({
  providedIn: 'root'
})
export class ManageNodesService {
  // geoCoder = new google.maps.Geocoder;
  /**
	 * Constructor
	 *
	 * @param {HttpClient} _httpClient
	 */
  constructor(
    private _httpClient: HttpClient
  ) { }

  // public getPosition(): Observable<Position> {
  //   return Observable.create(
  //     (observer) => {
  //       navigator.geolocation.watchPosition((pos: Position) => {
  //         observer.next(pos);
  //       });
  //     });
  // }

  async getDevices(): Promise<any> {
    return await this._httpClient.get(environment.baseUrl + 'devices').toPromise();
  }

  public saveDevice(data): Promise<any> {
    return this._httpClient.post(environment.baseUrl + 'addDevice', data).toPromise();
  }

  public getDeviceById(id): Promise<any> {
    return this._httpClient.get(environment.baseUrl + 'getDeviceByID?ID=' + id).toPromise();
  }

  public updateDevice(data): Promise<any> {
    return this._httpClient.post(environment.baseUrl + 'updateDevice', data).toPromise();
  }

  public checkDeviceIdNotTaken(devId): Promise<any> {
    return this._httpClient.get(environment.baseUrl + 'deviceIdExists?devId=' + devId).toPromise();
  }

  public deleteDevice(devId): Promise<any> {
    return this._httpClient.delete(environment.baseUrl + 'deleteDevice?ID=' + devId).toPromise();
  }
}
