export class PlantLogic {
  public static records = {
    data: [
      {
        id: 'irrigation_cycles',
        value: 'Drain Detail',
        type: 'irrigationTable',
        data: {
          labels: [],
          datasets: [
            {
              data: [],
            }
          ]
        }
      },
    {
      id: 'drainAverage',
      value: 'Daily Drain %',
      type: 'bar',
      data: {
        labels: [],
        datasets: [
          {
            label: 'Daily Drain %',
            data: [],
            backgroundColor: '#56b64b'
          }
        ]
      },
      options: {
        spanGaps: false,
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 24,
            left: 16,
            right: 16,
            bottom: 16
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              color: 'transparent',
            }
          }],
          yAxes: [{
            display: false,
            gridLines: {
              color: 'transparent',
            }
          }]
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end'
          }
        }
      }
    },
    {
      value: 'Average Drain EC',
      type: 'bar',
      id: 'ecAverage',
      colors: [
        {
          borderColor: '#42a5f5',
          backgroundColor: '#42a5f5'
        }
      ],
      datasets: [
        {
          label: 'Average Drain EC',
          data: []
        }
      ],
      options: {
        spanGaps: false,
        legend: {
          display: false
        },
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 24,
            left: 16,
            right: 16,
            bottom: 16
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              color: 'transparent',
            }
          }],
          yAxes: [{
            display: false,
            gridLines: {
              color: 'transparent',
            }
          }]
        },
        responsive: true,
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end'
          }
        }
      }
    },
    {
      value: 'Air Temp',
      name: 'SensorMaxTemperature',
      id: 'maxTemp',
      animationEnabled: true,
      type: 'line',
      axisX: {
        title: 'Time',
        labelFontFamily: 'Muli',
        titleFontSize: 16,
        labelFontSize: 12,
        lineColor: '#f2f2f2',
        titleFontFamily: 'Muli',
        valueFormatString: 'h TT',
        tickColor: 'transparent',
        tickLength: 20,
        titleFontColor: '#000000',
        labelFontColor: '#000000',
        crosshair: {
          valueFormatString: 'hh:mm TT',
          enabled: true,
          snapToDataPoint: true
        }
      },
      axisY: {
        gridColor: '#f2f2f2',
        lineColor: '#f2f2f2',
        title: '°C',
        tickColor: '#f2f2f2',
        tickLength: 10
      },
      toolTip: {
        shared: true,
        backgroundColor: 'rgba(255 ,255 , 255, 0.9)',
        cornerRadius: 4,
        borderColor: '#fff'
      },
      data: [
        {
          type: 'line',
          markerType: 'none',
          lineThickness: 2.2,
          color: '#4e9d63',
          name: 'Inside Temp',
          xValueFormatString: 'DD-MMM-YYYY HH:mm',
          dataPoints: []
        },
        {
          type: 'line',
          name: 'Outside Temp',
          markerType: 'none',
          lineThickness: 2.2,
          color: '#f7a034',
          xValueFormatString: 'DD-MMM-YYYY HH:mm',
          dataPoints: []
        }
      ]
    },
    {
      value: 'Humidity',
      name: 'SensorMinTemperature',
      id: 'humidity',
      animationEnabled: true,
      type: 'line',
      axisX: {
        title: 'Time',
        labelFontFamily: 'Muli',
        titleFontSize: 16,
        labelFontSize: 12,
        lineColor: '#f2f2f2',
        titleFontFamily: 'Muli',
        valueFormatString: 'h TT',
        tickColor: 'transparent',
        tickLength: 20,
        titleFontColor: '#000000',
        labelFontColor: '#000000',
        crosshair: {
          valueFormatString: 'hh:mm TT',
          enabled: true,
          snapToDataPoint: true
        }
      },
      axisY: {
        gridColor: '#f2f2f2',
        lineColor: '#f2f2f2',
        title: '%',
        tickColor: '#f2f2f2',
        tickLength: 10
      },
      toolTip: {
        shared: true,
        backgroundColor: 'rgba(255 ,255 , 255, 0.9)',
        cornerRadius: 4,
        borderColor: '#fff'
      },
      data: [
        {
          type: 'line',
          markerType: 'none',
          lineThickness: 2.2,
          color: '#4e9d63',
          name: 'Inside Humidity',
          xValueFormatString: 'DD-MMM-YYYY HH:mm',
          dataPoints: []
        },
        {
          type: 'line',
          name: 'Outside Humidity',
          markerType: 'none',
          lineThickness: 2.2,
          color: '#f7a034',
          xValueFormatString: 'DD-MMM-YYYY HH:mm',
          dataPoints: []
        }
      ]
    },
    {
      value: 'Average Drain pH',
      id: 'phAverage',
      type: 'bar',
      colors: [
        {
          borderColor: '#54595e',
          backgroundColor: '#54595e'
        }
      ],
      datasets: [
        {
          label: 'Average Drain pH',
          data: []
        }
      ],
      options: {
        spanGaps: false,
        legend: {
          display: false
        },
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 24,
            left: 16,
            right: 16,
            bottom: 16
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              color: 'transparent',
            }
          }],
          yAxes: [{
            display: false,
            gridLines: {
              color: 'transparent',
            }
          }]
        },
        responsive: true,
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',

          }
        }
      }
    },
    {
      value: 'Irrigation Water Temp',
      name: 'IrrigationTemp',
      type: 'waterTemp',
      animationEnabled: true,
      axisX: {
        title: 'Time',
        labelFontFamily: 'Muli',
        titleFontSize: 16,
        labelFontSize: 12,
        lineColor: '#f2f2f2',
        titleFontFamily: 'Muli',
        valueFormatString: 'h TT',
        tickColor: 'transparent',
        tickLength: 20,
        titleFontColor: '#000000',
        labelFontColor: '#000000',
        crosshair: {
          valueFormatString: 'hh:mm TT',
          enabled: true,
          snapToDataPoint: true
        }
      },
      axisY: {
        gridColor: '#f2f2f2',
        lineColor: '#f2f2f2',
        title: '°C',
        tickColor: '#f2f2f2',
        tickLength: 10
      },
      toolTip: {
        shared: true,
        backgroundColor: 'rgba(255 ,255 , 255, 0.9)',
        cornerRadius: 4,
        borderColor: '#fff'
      },
      data: [
        {
          type: 'line',
          markerType: 'none',
          lineThickness: 2.2,
          color: '#4e9d63',
          name: 'Drip Temp',
          xValueFormatString: 'DD-MMM-YYYY HH:mm',
          dataPoints: []
        },
        {
          type: 'line',
          name: 'Drain Temp',
          markerType: 'none',
          lineThickness: 2.2,
          color: '#fac637',
          xValueFormatString: 'DD-MMM-YYYY HH:mm',
          dataPoints: []
        }
      ]
    },
    {
      value: 'Pot Temp',
      name: 'PotTemp',
      type: 'potTemp',
      animationEnabled: true,
      axisX: {
        title: 'Time',
        labelFontFamily: 'Muli',
        titleFontSize: 16,
        labelFontSize: 12,
        lineColor: '#f2f2f2',
        titleFontFamily: 'Muli',
        valueFormatString: 'h TT',
        tickColor: 'transparent',
        tickLength: 20,
        titleFontColor: '#000000',
        labelFontColor: '#000000',
        crosshair: {
          valueFormatString: 'hh:mm TT',
          enabled: true,
          snapToDataPoint: true
        }
      },
      axisY: {
        gridColor: '#f2f2f2',
        lineColor: '#f2f2f2',
        title: '°C',
        tickColor: '#f2f2f2',
        tickLength: 10
      },
      toolTip: {
        shared: true,
        backgroundColor: 'rgba(255 ,255 , 255, 0.9)',
        cornerRadius: 4,
        borderColor: '#fff'
      },
      data: [
        {
          type: 'line',
          name: 'Top',
          markerType: 'none',
          lineThickness: 2.2,
          color: '#fac637',
          xValueFormatString: 'DD-MMM-YYYY HH:mm',
          dataPoints: []
        },
        {
          type: 'line',
          markerType: 'none',
          lineThickness: 2.2,
          color: '#4e9d63',
          name: 'Middle',
          xValueFormatString: 'DD-MMM-YYYY HH:mm',
          dataPoints: []
        },
        {
          type: 'line',
          markerType: 'none',
          lineThickness: 2.2,
          name: 'Bottom',
          color: '#f7a034',
          xValueFormatString: 'DD-MMM-YYYY HH:mm',
          dataPoints: []
        }
      ]
    },
    {
      value: 'Battery Condition',
      name: 'PotEC',
      type: 'battery',
      animationEnabled: true,
      axisX: {
        title: 'Time',
        labelFontFamily: 'Muli',
        titleFontSize: 16,
        labelFontSize: 12,
        lineColor: '#f2f2f2',
        titleFontFamily: 'Muli',
        valueFormatString: 'h TT',
        tickColor: 'transparent',
        tickLength: 20,
        titleFontColor: '#000000',
        labelFontColor: '#000000',
        crosshair: {
          valueFormatString: 'hh:mm TT',
          enabled: true,
          snapToDataPoint: true
        }
      },
      axisY: {
        gridColor: '#f2f2f2',
        lineColor: '#f2f2f2',
        title: 'volts',
        tickColor: '#f2f2f2',
        tickLength: 10
      },
      toolTip: {
        shared: true,
        backgroundColor: 'rgba(255 ,255 , 255, 0.9)',
        cornerRadius: 4,
        borderColor: '#fff'
      },
      data: [
        {
          type: 'line',
          name: 'Battery Voltage',
          markerType: 'none',
          lineThickness: 2.2,
          color: '#fac637',
          xValueFormatString: 'DD-MMM-YYYY HH:mm',
          dataPoints: []
        },
      ]
    }
    ]
  };
}
