import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Headers } from '@angular/http';
import { Router, CanActivate } from '@angular/router';
import { environment } from '../../../../../src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate {
  private headers: any;
  isUserDevices = false;

  constructor(private _http: HttpClient, public _router: Router) {
    this.headers = new Headers();
    this.createAuthorizationHeader(this.headers);
  }

  createAuthorizationHeader(headers: Headers): void{
    headers.append('Authorization', 'Basic ' + btoa('username:password'));
  }

  async canActivate(): Promise<boolean> {
    try {
      const data = await this.isValidToken();
      this.isUserDevices = data.hasDevices;
      if (data && data.isActive === '0') {
        this.clearLocalStorage();
      }
      return true;  
    } catch (e) {
      this._router.navigate(['auth/login']);
      this.clearLocalStorage();
    }
  }

  login(data): Promise<any> {
    return this._http.post(environment.baseUrl + 'auth/login', data).toPromise();
  }

  forgotPassword(data): Promise<any> {
    data.userType = environment.userType;
    return this._http.post(environment.baseUrl + 'auth/forgotPassword', data).toPromise();
  }

  resetPassword(data): Promise<any> {
    data.userType = environment.userType;
    return this._http.post(environment.baseUrl + 'auth/resetPassword', data).toPromise();
  }

  checkTokenExist(data): Promise<any> {
    return this._http.get(environment.baseUrl + 'auth/checkTokenExist?token=' + data + '&userType=' + environment.userType).toPromise();
  }

  isValidToken(): Promise<any> {
    return this._http.get(environment.baseUrl + 'isValidToken').toPromise();
  }

  logout(): Promise<any> {
    return this._http.get(environment.baseUrl + 'logout').toPromise();
  }
  
  clearLocalStorage(): boolean {
    localStorage.removeItem('ctoken');
    localStorage.removeItem('cuserInfo');
    this._router.navigate(['auth/login']);
    return false;
  }
}
