// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  baseUrl: 'https://test.ldc.getplantlogic.com/primaryAPI/api/v1/',
//  baseUrl: 'https://test.ldc.getplantlogic.com/devAPI/api/v1/',
  // baseUrl: 'http://10.0.80.13/primaryAPI/api/v1/',
  cryptoSalt: '6P1KGsh1MooevcvJAOucWPQr',
  userType: 'customer',
  soilMoistureDevice: 'plantlogic_board_843668'
};
