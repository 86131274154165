import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatIconModule, MatMenuModule, MatToolbarModule, MatInputModule } from '@angular/material';
import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    ToolbarComponent
  ],
  imports: [
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    FuseSharedModule,
    FuseSearchBarModule,
    FuseShortcutsModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    FuseSharedModule,
    MatInputModule,
    MatSelectModule
  ],
  exports: [
    ToolbarComponent
  ]
})
export class ToolbarModule {
}
