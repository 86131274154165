import { Component, ViewChild, ViewEncapsulation, OnInit, TemplateRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ManageNodesService } from '../manage-nodes.service';
import { SharedService, DeviceAlarms } from '../../../../../utils/sharedService';
import { AuthService } from 'app/main/pages/authentication/auth.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { CalibrateModalComponent } from '../../calibrate-modal/calibrate-modal.component';
import { fuseAnimations } from '@fuse/animations';
import { ConfirmModalComponent } from '../../common/confirm-modal/confirm-modal.component';
import { AlarmModalComponent } from '../../common/alarm-modal/alarm-modal.component';

@Component({
  selector: 'node-list',
  templateUrl: './node-list.component.html',
  styleUrls: ['./node-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})

export class NodeListComponent implements OnInit {
  allDevices = [];
  displayedColumns: string[] = ['Name', 'Type', 'Location', 'Notes', 'actionsColumn'];
  alarmColumns: string[] = ['type', 'highValue', 'lowValue', 'highValueDelay', 'lowValueDelay', 'highValueRepeat', 'lowValueRepeat', 'highValueDisable', 'lowValueDisable', 'actionsColumn'];
  alarmTypes: string[] = [];
  deviceAlarms: any;
  dataSource: MatTableDataSource<DeviceAlarms>;
  noRecordFound = false;
  noAlarms = true;
  requestInProgress = false;
  displayAlarmSettings = false;
  deviceID: number;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('confirmDialog') confirmDialog: TemplateRef<any>;
  dialogRef: any;
  openedDeviceId: string;

  constructor(
    public _manageNodeService: ManageNodesService,
    public _sharedService: SharedService,
    public _auth: AuthService,
    public dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
    this._sharedService.showLoading();
    this.requestInProgress = true;
    this._sharedService.getUserDevices().then((res) => {
      if (res.data && res.data.length) {
        this.allDevices = res.data;
        this.allDevices.forEach((device) => {
          device.Name = device.DevID;
          device.Notes = device.Description;
        });
        this.allDevices = res.data;
        this.dataSource = new MatTableDataSource(this.allDevices);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      } else {
        this.noRecordFound = true;
      }
      this.requestInProgress = false;
      this._sharedService.hideLoading();
    }, (error) => {
      this.router.navigate(['auth/login'])
      this.requestInProgress = false;
      this._sharedService.hideLoading();
    });
  }

  editNode(ev): void {
    const url = '/manage-nodes/edit/' + ev.ID;
    this.router.navigateByUrl(url);
  }

  calibrateNode(device: any): void {
    if (device.Type === 'Type 2' && device.custom_calibration) {
      device.custom_calibration.SensorDripVolumeMultiplier = 1000 / device.custom_calibration.SensorDripVolumeMultiplier;
    }
    const dialogRef = this.dialog.open(CalibrateModalComponent, {
      panelClass: 'custom-modalbox',
      data: device,
      maxWidth: '880px',
      width: '90%',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.allDevices.forEach((dev) => {
          if (dev.ID === result.DeviceID) {
            dev.custom_calibration = result;
          }
        });
      } else {
        if (device.Type === 'Type 2') {
          device.custom_calibration.SensorDripVolumeMultiplier = 1000 / device.custom_calibration.SensorDripVolumeMultiplier;
        }
      }
    });
  }

  showConfirmDailog(data): void {
    this.deviceID = data.ID;
    this.dialogRef = this.dialog.open(ConfirmModalComponent, { 
      data: { 
        header: 'Delete Node', 
        content: 'Are you sure you want to remove this node? This will delete all of the data on file for this node.' 
      },
      maxWidth: '600px', 
      width : '90%'
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result.data ? this.deleteNode() : this.dialogRef.close();
      }
    });
  }

  showAlarmConfirmDailog(data): void {
    this.deviceID = data.ID;
    this.dialogRef = this.dialog.open(ConfirmModalComponent, { 
      data: { 
        header: 'Delete Alarm', 
        content: 'Are you sure you want to remove this alarm?' 
      },
      maxWidth: '600px', 
      width : '90%'
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result.data) {
        this._sharedService.deleteDeviceAlarm(data.alarm_configuration_id).then(res => {
          this.deviceAlarms = new MatTableDataSource(res.data);
          this.noAlarms = !res.data.length;
        });
      } else {
        this.dialogRef.close();
      }
    });
  }

  openDeviceAlarms(deviceId: string) {
    if (deviceId !== this.openedDeviceId) {
      this._sharedService.getDeviceAlarms(deviceId).then(res => {
        this.deviceAlarms = new MatTableDataSource(res.data);
        this.noAlarms = !res.data.length;
        this.displayAlarmSettings = true;
        this.openedDeviceId = deviceId;
      });
    } else {
      this.displayAlarmSettings = !this.displayAlarmSettings;
    }
  }

  async addDeviceAlarm() {
    const alarmTypes = Object.entries((await this._sharedService.getAllowedAlarmTypes(this.openedDeviceId)).data);
    const dialogRef = this.dialog.open(AlarmModalComponent, {
      data: {
        alarmTypes,
        alarmConfig: {
          device_id: this.openedDeviceId,
          low_value_disable: false,
          high_value_disable: false
        }
      },
      maxWidth: '600px',
      width: '90%'
    });
    dialogRef.afterClosed().subscribe(async data => {
      if (data) {
        this._sharedService.addDeviceAlarm(data).then(res => {
          this.deviceAlarms = new MatTableDataSource(res.data);
          this.noAlarms = !res.data.length;
        });
      }
    });
  }

  async editDeviceAlarm(alarmConfig: DeviceAlarms) {
    const alarmObj = Object.assign({}, alarmConfig);
    alarmObj.alarm_type = alarmObj.alarm_type.replace(/\s/g, '');
    const dialogRef = this.dialog.open(AlarmModalComponent, {
      data: {
        alarmTypes: [[alarmObj.alarm_type, alarmConfig.alarm_type]],
        alarmConfig: alarmObj
      },
      maxWidth: '600px',
      width: '90%'
    });
    dialogRef.afterClosed().subscribe(async data => {
      if (data) {
        this._sharedService.editDeviceAlarm(data).then(res => {
          this.deviceAlarms = new MatTableDataSource(res.data);
        });
      }
    });
  }

  deleteNode(): void {
    this._sharedService.showLoading();
    this._manageNodeService.deleteDevice(this.deviceID).then((res) => {
      this._sharedService.hideLoading();
      const index = this.allDevices.findIndex(x => x.ID === this.deviceID);
      if (index > -1) {
        this.allDevices.splice(index, 1);
        this.noRecordFound = this.allDevices && this.allDevices.length ? false : true; 
        this.dataSource = new MatTableDataSource(this.allDevices);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
      this.dialogRef.close();
      if (res.status === 'success') {
        this._sharedService.showSuccess(res.message);
      }
    }, (e) => {
      this.dialogRef.close();
      this._sharedService.hideLoading();
    });
  } 
}

export interface AlarmModalData {
  alarmTypes?: string[];
  alarmConfig?: DeviceAlarms;
}
