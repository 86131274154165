import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICustomCalibration } from '../manage-nodes/interfaces';
import { SharedService } from '../../../../utils/sharedService';
import { AutoCalibrateModalComponent } from './auto-calibrate-modal/auto-calibrate-modal.component';

@Component({
  selector: 'app-calibrate-modal',
  templateUrl: './calibrate-modal.component.html',
  styleUrls: ['./calibrate-modal.component.scss']
})
export class CalibrateModalComponent implements OnInit {
  calibrateForm: FormGroup;
  numericId: number;
  devID: string;
  deviceType: string;
  public deviceConfig: any;

  hideTypeThreeControls: BooleanConstructor;

  constructor(
    public fb: FormBuilder,
    public sharedService: SharedService,
    private dialogRef: MatDialogRef<CalibrateModalComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.numericId = data.ID;
    this.devID = data.DevID;
    this.deviceType = data.Type;
    this.deviceConfig = data.custom_calibration;
  }

  ngOnInit(): void {

    this.calibrateForm = this.fb.group({
      DeviceID: [''],
      SoilTopMoistureIntercept: ['', Validators.required],
      SoilTopMoistureMultiplier: ['', Validators.required],
      SoilMiddleMoistureIntercept: ['', this.deviceType === 'Type 1' ? Validators.required : Validators.nullValidator],
      SoilMiddleMoistureMultiplier: ['', this.deviceType === 'Type 1' ? Validators.required : Validators.nullValidator],
      SoilBottomMoistureIntercept: ['', Validators.required],
      SoilBottomMoistureMultiplier: ['', Validators.required],
      SensorDripECIntercept: ['', Validators.required],
      SensorDripECMultiplier: ['', Validators.required],
      SensorDripPHIntercept: ['', Validators.required],
      SensorDripPHMultiplier: ['', Validators.required],
      SensorDrainECIntercept: ['', Validators.required],
      SensorDrainECMultiplier: ['', Validators.required],
      SensorDrainPHIntercept: ['', Validators.required],
      SensorDrainPHMultiplier: ['', Validators.required],
      SensorDrainVolumeIntercept: ['', this.deviceType !== 'Type 3' ? Validators.required : Validators.nullValidator],
      SensorDrainVolumeMultiplier: ['', this.deviceType !== 'Type 3' ? Validators.required : Validators.nullValidator],
      SoilTopTemperatureIntercept: ['', Validators.required],
      SoilTopTemperatureMultiplier: ['', Validators.required],
      SoilMiddleTemperatureIntercept: ['', this.deviceType === 'Type 1' ? Validators.required : Validators.nullValidator],
      SoilMiddleTemperatureMultiplier: ['', this.deviceType === 'Type 1' ? Validators.required : Validators.nullValidator],
      SoilBottomTemperatureIntercept: ['', Validators.required],
      SoilBottomTemperatureMultiplier: ['', Validators.required],
      SensorDripTemperatureIntercept: ['', Validators.required],
      SensorDripTemperatureMultiplier: ['', Validators.required],
      SensorDrainTemperatureIntercept: ['', Validators.required],
      SensorDrainTemperatureMultiplier: ['', Validators.required],
      SensorDripVolumeIntercept: ['', this.deviceType === 'Type 1' ? Validators.required : Validators.nullValidator],
      SensorDripVolumeMultiplier: ['', this.deviceType !== 'Type 3' ? Validators.required : Validators.nullValidator],
      BatteryVoltsIntercept: ['', Validators.required],
      BatteryVoltsMultiplier: ['', Validators.required],
      SensorInsideHumidityIntercept: ['', Validators.required],
      SensorInsideHumidityMultiplier: ['', Validators.required],
      SensorOutsideHumidityIntercept: ['', this.deviceType === 'Type 1' ? Validators.required : Validators.nullValidator],
      SensorOutsideHumidityMultiplier: ['', this.deviceType === 'Type 1' ? Validators.required : Validators.nullValidator],
      SensorInsideTemperatureIntercept: ['', Validators.required],
      SensorInsideTemperatureMultiplier: ['', Validators.required],
      SensorOutsideTemperatureIntercept: ['', this.deviceType === 'Type 1' ? Validators.required : Validators.nullValidator],
      SensorOutsideTemperatureMultiplier: ['', this.deviceType === 'Type 1' ? Validators.required : Validators.nullValidator],
      SensorDripperDripVolume: ['', this.deviceType === 'Type 3' ? Validators.required : Validators.nullValidator],
      SensorDripperDripPulseVolume: ['', this.deviceType === 'Type 3' ? Validators.required : Validators.nullValidator],
      SensorDripperDrainVolume: ['', this.deviceType === 'Type 3' ? Validators.required : Validators.nullValidator],
      SensorDripperDrainPulseVolume: ['', this.deviceType === 'Type 3' ? Validators.required : Validators.nullValidator]
    });
    this.calibrateForm.patchValue(this.deviceConfig);
  }

  saveConstants(): void {
    const data = this.calibrateForm.value;
    if (this.deviceType === 'Type 2') {
      data.SensorDripVolumeMultiplier = 1000 / data.SensorDripVolumeMultiplier;
      data.SensorDripVolumeIntercept = '0';
    }
    data.DeviceID = this.numericId;
    this.sharedService.updateCalibrationValues(data).then((res) => {
      if (res.status === 'success') {
        this.sharedService.showSuccess(res.message);
        this.dialogRef.close(data);
      }
    }, (error) => {
      this.dialogRef.close();
    });
  }

  resetAccumulation(drain = true) {
    this.sharedService.resetAccumulation({devID: this.devID, drain});
  }

  manualPump(pump = '20') {
    this.sharedService.manualPump({devID: this.devID, pump});
  }

  openModal(pump = '20') {
    const dialogRef = this.dialog.open(AutoCalibrateModalComponent, {
      panelClass: 'custom-modalbox',
      data: { devID: this.devID, pump },
      maxWidth: '400px',
      width: '50%',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (pump === '20') {
        this.calibrateForm.patchValue({
          SensorDrainVolumeMultiplier: result.multiplier, SensorDrainVolumeIntercept: result.intercept
        });
      } else {
        this.calibrateForm.patchValue({
          SensorDripVolumeMultiplier: result.multiplier, SensorDripVolumeIntercept: result.intercept
        });
      }
    });
  }

  controls(controlName: string): any {
    return this.calibrateForm.get(controlName);
  }
}
