import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NodeListComponent } from './node-list/node-list.component';
import { NodeAddEditComponent } from './node-add-edit/node-add-edit.component';
import { RouterModule, Routes } from '@angular/router';
import { MaterialModule } from '../../shared-module/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ManageNodesService } from './manage-nodes.service';
import { FuseSharedModule } from '@fuse/shared.module';
import { CalibrateModalModule } from '../calibrate-modal/calibrate-modal.module';
import { CalibrateModalComponent } from '../calibrate-modal/calibrate-modal.component';
import { ConfirmModalComponent } from '../common/confirm-modal/confirm-modal.component';
import { ConfirmModalModule } from '../common/confirm-modal/confirm-modal.module';
import { AlarmModalComponent } from '../common/alarm-modal/alarm-modal.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AutoCalibrateModalComponent } from '../calibrate-modal/auto-calibrate-modal/auto-calibrate-modal.component';

const routes: Routes = [
  {
    path: '',
    component: NodeListComponent
  },
  {
    path: 'add',
    component: NodeAddEditComponent
  },
  {
    path: 'edit/:id',
    component: NodeAddEditComponent
  }
];

@NgModule({
  declarations: [
    NodeListComponent,
    NodeAddEditComponent,
    AlarmModalComponent,
    AutoCalibrateModalComponent
  ],
  imports: [
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    CommonModule,
    MaterialModule,
    FuseSharedModule,
    ConfirmModalModule,
    CalibrateModalModule,
    NgxMatSelectSearchModule
  ],
  providers: [
    ManageNodesService
  ], 
  entryComponents: [
    CalibrateModalComponent,
    ConfirmModalComponent,
    AlarmModalComponent,
    AutoCalibrateModalComponent
  ]
})
export class ManageNodesModule { }
