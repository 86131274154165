import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Messages } from './messages';
import { Router, CanActivate } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AuthService } from '../app/main/pages/authentication/auth.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { AES, enc } from 'crypto-js';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService implements CanActivate {
  profileUpdated: BehaviorSubject<any>;
  adminUsers: BehaviorSubject<any>;
  userChanged: BehaviorSubject<any>;

  constructor(
    private _router: Router,
    private _httpClient: HttpClient,
    private toaster: ToastrService,
    private _auth: AuthService,
    private loaderService: FuseProgressBarService
  ) {
    this.profileUpdated = new BehaviorSubject({});
    this.adminUsers = new BehaviorSubject([]);
    this.userChanged = new BehaviorSubject({});
  }


  async canActivate(): Promise<any> {
    try {
      const data = await this._auth.isValidToken();
      this._auth.isUserDevices = data.hasDevices;
      this._router.navigate(['apps/dashboards/analytics']);
      return false;
    } catch (e) {
      return true;
    }
  }

  requestErrorHandling(error: HttpErrorResponse): void {
    if (error.status === 401) {
      localStorage.removeItem('ctoken');
      this._router.navigate(['auth/login']);
    } else if (error.status === 400) {
      this.showError(Messages.badRequest);
    } else {
      if (typeof error.status !== 'undefined' && error.status && error.status > 300) {
        if (error.status === 422) {
          this.showError(Messages.validationErr);
        }
        else {
          this.showError(Messages.serverErr);
        }
      }
    }
  }

  showSuccess(msg: string): void {
    this.toaster.success(msg, Messages.success);
  }

  showError(msg: string): void {
    this.toaster.error(msg, Messages.error);
  }

  clearToastMessage(): void {
    this.toaster.clear();
  }

  decryptHash(key: string): string {
    return AES.decrypt(key, environment.cryptoSalt).toString(enc.Utf8);
  }

  encryptKey(key: string): string {
    return AES.encrypt(key, environment.cryptoSalt).toString();
  }

  showLoading(): void {
    this.loaderService.show();
  }

  hideLoading(): void {
    this.loaderService.hide();
  }

  mergeArrays(arrays, prop): Array<any> {
    const merged = {};

    arrays.forEach(arr => {
      arr.forEach(item => {
        merged[item[prop]] = Object.assign({}, merged[item[prop]], item);
      });
    });

    return Object.values(merged);
  }

  updateCalibrationValues(data): Promise<any> {
    return this._httpClient.post(environment.baseUrl + 'customCalibrate', data).toPromise();
  }

  public getUserDevices(): Promise<any> {
    return this._httpClient.get(environment.baseUrl + 'userRegisteredDevices')
      .toPromise();
  }

  getUserDevicesAlarms(userId: number): Promise<any> {
    return this._httpClient.get(`${environment.baseUrl}users/'${userId}'/devices/alarms`).toPromise();
  }

  getDeviceAlarms(deviceId: string): Promise<any> {
    return this._httpClient.get(`${environment.baseUrl}device/alarms/${deviceId}`).toPromise();
  }

  getAllowedAlarmTypes(deviceId: string): Promise<any> {
    return this._httpClient.get(`${environment.baseUrl}device/alarms/types/${deviceId}`).toPromise();
  }

  addDeviceAlarm(data: DeviceAlarms): Promise<any> {
    return this._httpClient.post(`${environment.baseUrl}device/alarms`, data).toPromise();
  }

  editDeviceAlarm(data: DeviceAlarms): Promise<any> {
    return this._httpClient.put(`${environment.baseUrl}device/alarms/${data.alarm_configuration_id}`, data).toPromise();
  }

  deleteDeviceAlarm(alarmConfigId: number): Promise<any> {
    return this._httpClient.delete(`${environment.baseUrl}device/alarms/${alarmConfigId}`).toPromise();
  }

  getLoggedUserDetails(): Promise<any> {
    return this._httpClient.get(`${environment.baseUrl}currentUserDetails`).toPromise();
  }

  updateUser(data): Promise<any> {
    return this._httpClient.post(`${environment.baseUrl}updateUser`, data).toPromise();
  }

  resetAccumulation(data): Promise<any> {
    return this._httpClient.post(`${environment.baseUrl}resetAccumulation`, data).toPromise();
  }

  manualPump(data): Promise<any> {
    return this._httpClient.post(`${environment.baseUrl}manualPump`, data).toPromise();
  }

  startCalibration(data): Promise<any> {
    return this._httpClient.post(`${environment.baseUrl}startCalibration`, data).toPromise();
  }

  endCalibration(data): Promise<any> {
    return this._httpClient.post(`${environment.baseUrl}endCalibration`, data).toPromise();
  }

  // 2022-07 (dreed) User Custom View Requests
  getUserCustomViews(userId): Promise<any> {
    return this._httpClient.get(`${environment.baseUrl}getUserCustomViews?uid=` + userId).toPromise();

  }

  deleteAllUserCustomViews(userId): Promise<any> {
    return this._httpClient.get(`${environment.baseUrl}deleteAllCustomViews?uid=` + userId).toPromise();
  }

  deleteUserCustomView(customview_id, userId): Promise<any> {
    return this._httpClient.get(`${environment.baseUrl}deleteUserCustomView?uid=` + userId + `&cvid=` + customview_id).toPromise();
  }

  saveuserCustomView(data): Promise<any> {
    return this._httpClient.post(`${environment.baseUrl}saveUserCustomView`, data).toPromise();
  }

  setLocalStorage(data): void {
    data.name = data.FirstName ? data.FirstName + ' ' + data.LastName : '';
    localStorage.setItem('cuserInfo', JSON.stringify({
      name: this.encryptKey(data.name), 
      email: this.encryptKey(data.Email),
      alertsEnable: this.encryptKey(data.EnableAlerts),
      timezone: this.encryptKey(data.Timezone),
      UserType: this.encryptKey(data.UserType)
    }));
  }

  getParentAccounts(): Promise<any> {
    return this._httpClient.get(environment.baseUrl + 'getParentAccounts').toPromise();
  }
}

export interface DeviceAlarms {
  alarm_configuration_id?: number;
  device_id: string;
  alarm_type?: string;
  low_value?: number;
  low_value_delay?: number;
  low_value_repeat?: number;
  low_value_disable?: boolean;
  high_value?: number;
  high_value_delay?: number;
  high_value_repeat?: number;
  high_value_disable?: boolean;
}

export interface UserCustomView {
  usercustomview_id: number;
  device_id: number;
  device_name: string;
  view_name: string;
  start_time: string;
  end_time: string;
}