import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { SharedService } from './sharedService';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    public _sharedService: SharedService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    const token = localStorage.getItem('ctoken');
    if (token) {
      request = request.clone({
        setHeaders: {
          'Authorization': 'bearer ' + token
        }
      });
      const ID = localStorage.getItem('userID');
      if (ID) {
        request = request.clone({
          setHeaders: {
            'UserID': ID
          }
        });
      }
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        setHeaders: {
          'content-type': 'application/json'
        }
      });
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json')
    });
    
    return next.handle(request);
  }
}
