import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SharedService } from 'utils/sharedService';

@Component({
  selector: 'app-auto-calibrate-modal',
  templateUrl: './auto-calibrate-modal.component.html',
  styleUrls: ['./auto-calibrate-modal.component.scss']
})
export class AutoCalibrateModalComponent {
  devID: string;
  pump: string;
  pumpName: string;
  pots: number;
  x1: number;
  x2: number;
  waiting = false;
  showFinalSteps = false;

  constructor(
    private dialogRef: MatDialogRef<AutoCalibrateModalComponent>,
    private sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) data) {
      this.devID = data.devID;
      this.pump = data.pump;
      this.pumpName = data.pump === '20' ? 'drain' : 'drip';
  }

  async startCalibration() {
    this.waiting = true;
    this.x1 = (await this.sharedService.startCalibration({ devID: this.devID, pump: this.pump })).data.data[this.pumpName];
    this.waiting = false;
    this.showFinalSteps = true;
  }

  async endCalibration() {
    this.waiting = true;
    this.x2 = (await this.sharedService.endCalibration({ devID: this.devID })).data.data[this.pumpName];
    if (this.x1 !== undefined && this.x2) {
      this.dialogRef.close(this.calcCalibration());
    }
  }

  private calcCalibration(): PointSlope {
    const multiplier = 3000 / (this.x2 - this.x1) / this.pots;
    const intercept = -multiplier * this.x1;
    return {
      multiplier,
      intercept
    }
  }
}

export interface PointSlope {
  multiplier: number;
  intercept: number;
}
