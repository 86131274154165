import { Component, Inject, OnDestroy, OnInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil, delay } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { navigation } from 'app/navigation/navigation';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
import { SharedService } from '../utils/sharedService';
import {
  Router, Event as RouterEvent} from '@angular/router';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterContentChecked {
  fuseConfig: any;
  navigation: any;
  public loader = true;
  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseProgressBarService: FuseProgressBarService,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _platform: Platform,
    private _cdRef: ChangeDetectorRef,
    private _router: Router,
    private _sharedService: SharedService
  ) {
    // Get default navigation
    this.navigation = navigation;

    // Register the navigation to the service
    this._fuseNavigationService.register('main', this.navigation);

    // Set the main navigation as our current navigation
    this._fuseNavigationService.setCurrentNavigation('main');

    // Add languages
    this._translateService.addLangs(['en', 'tr']);

    // Set the default language
    this._translateService.setDefaultLang('en');

    // Set the navigation translations
    this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

    // Use a language
    this._translateService.use('en');

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this._sharedService.getLoggedUserDetails().then(resp => {
      if (resp && resp.data) {
        if (resp.data.hasOwnProperty('isAdmin')) {
          localStorage.setItem('isAdmin', resp.data.isAdmin);
          this._sharedService.getParentAccounts().then(response => {
            if (response && response.status === 'success') {
              this._sharedService.adminUsers.next(response.data);
            }
          }, error => {
            console.log(error);
          });
        }
        
        if (!resp.data.Timezone) {
          const data = {
            Timezone: moment.tz.guess()
          };
          
          this._sharedService.updateUser(data).then(response => {
            resp.data.Timezone  = data.Timezone;
            if (response && response.message) {
              this._sharedService.setLocalStorage(resp.data);
            }
          }, error => {
            console.log(error);
          });
        }
        this._sharedService.setLocalStorage(resp.data);
      }
    }, error => {
      console.log(error);
    });

    this._fuseProgressBarService.visible.subscribe(val => {
      val ? document.body.classList.add('cursor-progress') : document.body.classList.remove('cursor-progress');
      this.loader = val;
    });
    
    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {

        this.fuseConfig = config;

        // Boxed
        if (this.fuseConfig.layout.width === 'boxed') {
          this.document.body.classList.add('boxed');
        }
        else {
          this.document.body.classList.remove('boxed');
        }

        // Color theme - Use normal for loop for IE11 compatibility
        for (let i = 0; i < this.document.body.classList.length; i++) {
          const className = this.document.body.classList[i];

          if (className.startsWith('theme-')) {
            this.document.body.classList.remove(className);
          }
        }

        this.document.body.classList.add(this.fuseConfig.colorTheme);
      });
  }

  ngAfterContentChecked(): void {
    this._cdRef.detectChanges();
  }
  /**
	 * On destroy
	 */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
}
