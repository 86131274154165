import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { AuthService } from '../../../main/pages/authentication/auth.service';
import { navigation } from 'app/navigation/navigation';
import { SharedService } from '../../../../utils/sharedService';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  projects: any[];
  selectedProject: any;
  userName: string;
  users = [];
  isAdmin = 'false';
  public selectedUser: FormControl = new FormControl();
  public isMobile = false;
  // Private
  private _unsubscribeAll: Subject<any>;
  protected _onDestroy = new Subject<void>();

  /**
	 * Constructor
	 *
	 * @param {FuseConfigService} _fuseConfigService
	 * @param {FuseSidebarService} _fuseSidebarService
	 * @param {TranslateService} _translateService
	 */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
    private _router: Router,
    private _sharedService: SharedService,
    private _authService: AuthService,
  ) {
    // Set the defaults
    this.userStatusOptions = [
      {
        'title': 'Online',
        'icon': 'icon-checkbox-marked-circle',
        'color': '#4CAF50'
      },
      {
        'title': 'Away',
        'icon': 'icon-clock',
        'color': '#FFC107'
      },
      {
        'title': 'Do not Disturb',
        'icon': 'icon-minus-circle',
        'color': '#F44336'
      },
      {
        'title': 'Invisible',
        'icon': 'icon-checkbox-blank-circle-outline',
        'color': '#BDBDBD'
      },
      {
        'title': 'Offline',
        'icon': 'icon-checkbox-blank-circle-outline',
        'color': '#616161'
      }
    ];

    this.languages = [
      {
        id: 'en',
        title: 'English',
        flag: 'us'
      },
      {
        id: 'tr',
        title: 'Turkish',
        flag: 'tr'
      }
    ];

    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
	 * On init
	 */
  ngOnInit(): void {
    this.isMobile =  this.detectmob();
    // Subscribe to the config changes
    this._sharedService.profileUpdated.pipe(takeUntil(this._unsubscribeAll)).subscribe(resp => {
      this.userName = resp.name;
    });

    this._sharedService.adminUsers.pipe(takeUntil(this._unsubscribeAll)).subscribe((data) => {
      if (data && data.length) {
        const id = +(localStorage.getItem('userID'));
        this.users = data;
        id ? this.selectedUser.setValue(id) : this.selectedUser.setValue(data[0].UserID);
        this.getUserDetails();
      }
    });

    this.selectedUser.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe((resp) => {
      if (resp) {
        localStorage.setItem('userID', resp);
        const index = this.users.findIndex(x => x.UserID === resp);
        if (index > -1) {
          this.getUserDetails();
          this._sharedService.userChanged.next({'timezone' : this.users[index]['Timezone']});
          this._router.navigate(['apps/dashboards/analytics']);
        }
      }
    });

    const userInfo = JSON.parse(localStorage.getItem('cuserInfo'));
    if (userInfo && userInfo.name)  {
       this.userName = this._sharedService.decryptHash(userInfo.name);
       this.isAdmin = localStorage.getItem('isAdmin');
    } else {
      this.getUserDetails();
    }

    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === 'top';
        this.rightNavbar = settings.layout.navbar.position === 'right';
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, { 'id': this._translateService.currentLang });
  }

  /**
	 * On destroy
	 */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
	 * Toggle sidebar open
	 *
	 * @param key
	 */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
	 * Search
	 *
	 * @param value
	 */
  search(value): void {
    // Do your search here...
    console.log(value);
  }

  /**
	 * Set the language
	 *
	 * @param lang
	 */
  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this._translateService.use(lang.id);
  }

  logout(): void {
    this._authService.logout().then(res => {
      localStorage.removeItem('ctoken');
      localStorage.removeItem('cuserInfo');
      localStorage.removeItem('userID');
      localStorage.removeItem('isAdmin');
      this._router.navigate(['auth/login']);
    });
  }

  getUserDetails(): void {
    this._sharedService.getLoggedUserDetails().then(resp => {
      if (resp && resp.data) {
        this.userName = resp.data.FirstName ? resp.data.FirstName + ' ' + resp.data.LastName : '';
        this._sharedService.setLocalStorage(resp.data);
        this.isAdmin = resp.data.isAdmin;
      }
    }, error => {
      console.log(error);
    });
  }

  detectmob(): boolean {
    const mobileView = window.innerWidth <= 740 ? true : false;
    return mobileView;
  }
}
