import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ManageNodesService } from '../manage-nodes.service';
import { DecorderType } from '../interfaces';
import { Location } from '@angular/common';
import { SharedService } from '../../../../../utils/sharedService';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../../common/confirm-modal/confirm-modal.component';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'node-add-edit',
  templateUrl: './node-add-edit.component.html',
  styleUrls: ['./node-add-edit.component.scss']
})
export class NodeAddEditComponent implements OnInit, OnDestroy {
  nodeAddForm: FormGroup;
  isEditPage = false;
  soils: DecorderType[] = [
    { value: 'Type 1', viewValue: 'Type 1' },
    { value: 'Type 2', viewValue: 'Type 2' },
    { value: 'Type 3', viewValue: 'Type 3' }
  ];
  deviceId;
  deviceIdTaken = false;
  @ViewChild('confirmDialog') confirmDialog: TemplateRef<any>;
  dialogRef: any;
  timer: any;
  timezoneArray: string[];
  private _unsubscribeAll: Subject<any>;
  public timeZoneCtrl: FormControl = new FormControl();
  public filterZoneArray: ReplaySubject<any> = new ReplaySubject<[]>(1);
  protected _onDestroy = new Subject<void>();

  constructor(
    private _formBuilder: FormBuilder,
    private _sharedService: SharedService,
    private _manageNodeService: ManageNodesService,
    private _location: Location,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {
    this._unsubscribeAll = new Subject();
   }

  ngOnInit(): void {
    this.timezoneArray = moment.tz.names();
    this.filterZoneArray.next(this.timezoneArray);
    this.intializeForm();
    this.deviceId = this.route.snapshot.paramMap.get('id');
    if (this.deviceId) {
      this.isEditPage = true;
      this.setFormFields(this.deviceId);
      this.nodeAddForm.controls['DevID'].disable();
    }
    this.nodeAddForm.controls['DevID'].valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
	    if (value) {
       this.checkDevIdExists(value);
      }
    });
    this.timeZoneCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterZones();
      });
  }

  setFormFields(id): void {
    this._sharedService.showLoading();
    this._manageNodeService.getDeviceById(id).then((res) => {
      this._sharedService.hideLoading();
      if (res.data) {
        this.nodeAddForm.patchValue(res.data);
      }
    }, (error) => {
      this._sharedService.hideLoading();
    });
  }

  intializeForm(): void {
    this.nodeAddForm = this._formBuilder.group({
      DevID: ['', [Validators.required, Validators.maxLength(65), Validators.minLength(2), Validators.pattern('^([a-z]+(((((?!\-).|^\-)|((?!\_).|^\_)|((?!\_).|^\_)|(((?!\_).|^\-)))*)?)?[a-z0-9]+(((((?!\_).|^\-)|((?!\_).|^\_)|((?!\_).|^\_)|(((?!\_).|^\-)))*)?)?[a-z0-9]+)*$')]],
      Type: ['', Validators.required],
      AppID: ['', Validators.required],
      Latitude: ['', [Validators.required, Validators.min(-90), Validators.max(90), Validators.pattern('^-?[0-9]*\.?[0-9]*$')]],
      Longitude: ['', [Validators.required, Validators.min(-180), Validators.max(180), Validators.pattern('^-?[0-9]*\.?[0-9]*$')]],
      Description: ['', [Validators.maxLength(128)]],
      Timezone: ['', Validators.required]
    });
  }

  setCurrentLocation(): void {
    // this._manageNodeService.getPosition().subscribe(
    //   (pos: Position) => {
    //     this.nodeAddForm.controls['Latitude'].setValue(pos.coords.latitude);
    //     this.nodeAddForm.controls['Longitude'].setValue(pos.coords.longitude);
    //   });
  }

  saveNode(): void {
    this.isEditPage ? this.updateNode() : this.addNode();
  }

  addNode(): void {
    this._sharedService.showLoading();
    this._manageNodeService.saveDevice(this.nodeAddForm.value).then((res) => {
      this._sharedService.hideLoading();
      if (res.status === 'success') {
        this._sharedService.showSuccess(res.message);
      }
      this._location.back();
    }, (error) => {
      this._location.back();
      this._sharedService.hideLoading();
    });
  }

  updateNode(): void {
    const data = this.nodeAddForm.value;
    data.DevId = this.deviceId;
    this._sharedService.showLoading();
    this._manageNodeService.updateDevice(data).then((res) => {
      this._sharedService.hideLoading();
      if (res.status === 'success') {
        this._sharedService.showSuccess(res.message);
      }
      this._location.back();
    }, (error) => {
      this._location.back();
      this._sharedService.hideLoading();
    });
  }

  goBack(): void {
    this._location.back();
  }

  controls(controlName: string): any {
    return this.nodeAddForm.get(controlName);
  }

  showConfirmDailog(data): void {
    this.dialogRef = this.dialog.open(ConfirmModalComponent, { 
      data: { 
        header: 'Delete Node', 
        content: 'Are you sure you want to remove this node? This will delete all of the data on file for this node.' 
      },
      maxWidth: '400px', 
      width : '90%'
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result.data ? this.deleteNode() : this.dialogRef.close();
      }
    });
  }

  deleteNode(): void {
    this._sharedService.showLoading();
    this._manageNodeService.deleteDevice(this.deviceId).then((res) => {
      this._sharedService.hideLoading();
      this.dialogRef.close();
      if (res.status === 'success') {
        this._sharedService.showSuccess(res.message);
      }
      this._location.back();
    }, (e) => {
      this._location.back();
      this.dialogRef.close();
      this._sharedService.hideLoading();
    });
  }

  private filterZones(): void {
    if (!this.timezoneArray) {
      return;
    }
    let search = this.timeZoneCtrl.value;
    
    if (!search) {
      this.filterZoneArray.next(this.timezoneArray.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filterZoneArray.next(
      this.timezoneArray.filter(bank => bank.toLowerCase().indexOf(search) > -1)
    );
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  checkDevIdExists(ev): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      this._manageNodeService.checkDeviceIdNotTaken(ev).then((res) => {
        if (res.data) {
          this.nodeAddForm.controls['DevID'].setErrors({
            deviceIdTaken: true
          });
        }
      });
    }, 1000);
  }
}
