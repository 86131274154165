export const locale = {
  lang: 'en',
  data: {
    'NAV': {
      'APPLICATIONS': 'Applications',
      'DASHBOARD': 'Dashboard',
      'MAP': 'Map',
      'ABOUT': 'About',
      'SUPPORT': 'Support'
    }
  }
};
